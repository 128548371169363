import React from 'react';
import css from './ListingPage.module.css';
import IconShippingTruck from '../../components/IconShippingTruck/IconShippingTruck';
import IconReturn from '../../components/IconReturn/IconReturn';
import IconPayment from '../../components/IconPayment/IconPayment';
import { getAuthorBio, getDisplayName, getStoreName, getUsername } from '../../util/dataExtractors';
import { NamedLink } from '../../components';
import { useSelector } from 'react-redux';
import { selectAuthorDescription } from './ListingPage.duck';

function SectionBrand(props) {
  const { listing } = props;
  const author = listing?.author;
  const brandName = getStoreName(author) || getDisplayName(author);
  const userName = getUsername(author);
  const description = useSelector(selectAuthorDescription);
  const bio = description || getAuthorBio(author);
  const userId = author?.id?.uuid;

  return (
    <div className={css.aboutBrand}>
      <h2>
        About{' '}
        {userId ? (
          <NamedLink
            name={userName ? 'StorePage' : 'ProfilePage'}
            params={userName ? { username: userName || userId } : { id: userId }}
            to={{ state: { userId } }}
          >
            {brandName}
          </NamedLink>
        ) : null}
      </h2>
      <p> {bio}</p>
      {/* <div className={css.aboutDelivery}>
        <div className={css.deliverInfo}>
          <IconShippingTruck />
          <h2>Free Delivery</h2>
          <p>Delivered on Thursday, 10 February 2022</p>
        </div>{' '}
        <div className={css.deliverInfo}>
          <IconReturn />
          <h2>Free Returns</h2>
          <p>Return through your local post office</p>
        </div>{' '}
        <div className={css.deliverInfo}>
          <IconPayment />
          <h2>Safe Payments</h2>
          <p>Secure payment online with Card or Paypal</p>
        </div>
      </div> */}
    </div>
  );
}

export default SectionBrand;
