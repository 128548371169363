import React from 'react';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { toast } from 'react-toastify';
import { Modal, Toastify } from '../../../components';
import config from '../../../config';
import { TUTULIST } from '../../../util/enums';
import { copyToClipboard } from '../../../util/genericHelpers';
import css from './SocialSharing.module.css';

function SocialSharing(props) {
  const {
    onManageDisableScrolling,
    onOpenShareModal,
    isSocialSharingModalOpen,
    location,
    listing,
  } = props;

  const currentURL = config.canonicalRootURL + location.pathname + location.search;
  const listingTitle = listing?.attributes?.title || TUTULIST;

  const sharingModal = (
    <Modal
      id="ListingPageFullImage"
      isOpen={isSocialSharingModalOpen}
      onClose={() => onOpenShareModal(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      modalTitle={`Share ${listingTitle}`}
      isSocialSharingModal={true}
    >
      <div className={css.shareContainer}>
        <ul className={css.socialMedialinks}>
          <li>
            <FacebookShareButton url={currentURL} quote={listingTitle}>
              <span className={css.leftIcon}>
                <svg
                  width="24"
                  height="24"
                  fill="#000000"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill="#3F3F46"
                      d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"
                    ></path>
                  </g>
                </svg>
                Facebook
              </span>
            </FacebookShareButton>
          </li>
          <li>
            <FacebookMessengerShareButton
              url={currentURL}
              quote={listingTitle}
              appId="2943206489158222"
            >
              <span className={css.leftIcon}>
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.31513 14.9079V18.1382L6.33568 16.421C7.17766 16.6637 8.07268 16.7946 9 16.7946C13.9709 16.7946 18 13.0347 18 8.39732C18 3.75959 13.9709 0 9 0C4.02943 0 0 3.75959 0 8.39732C0 11.0234 1.29198 13.3682 3.31513 14.9079Z"
                    fill="#484848"
                  ></path>
                  <path
                    d="M8.03892 6.09741L3.18527 11.2364L7.6025 8.81284L9.91044 11.2364L14.7367 6.09741L10.3685 8.47856L8.03892 6.09741Z"
                    fill="white"
                  ></path>
                </svg>
                Messenger
              </span>
            </FacebookMessengerShareButton>
          </li>
          <li>
            <TwitterShareButton title={listingTitle} url={currentURL}>
              <svg
                width="18"
                height="19"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.78251 21C16.3295 21 20.4592 14.4577 20.4592 8.7938C20.4592 8.60974 20.4592 8.42569 20.4512 8.24163C21.2515 7.63927 21.9478 6.87795 22.5 6.01624C21.7637 6.35925 20.9714 6.58514 20.1391 6.6939C20.9874 6.16683 21.6357 5.32185 21.9478 4.31791C21.1555 4.81152 20.2751 5.16289 19.3387 5.35531C18.5864 4.5187 17.522 4 16.3455 4C14.0806 4 12.2399 5.92421 12.2399 8.29183C12.2399 8.62648 12.2799 8.95276 12.3439 9.27067C8.93457 9.09498 5.90936 7.37992 3.88455 4.78642C3.53241 5.42224 3.33233 6.15846 3.33233 6.94488C3.33233 8.43406 4.06062 9.74754 5.15706 10.5172C4.48479 10.4921 3.85254 10.2997 3.30032 9.98179C3.30032 9.99852 3.30032 10.0153 3.30032 10.0404C3.30032 12.1152 4.71689 13.8553 6.58964 14.2485C6.2455 14.3489 5.88535 14.3991 5.5092 14.3991C5.2451 14.3991 4.989 14.374 4.7409 14.3238C5.2611 16.0305 6.78171 17.2687 8.57443 17.3022C7.16587 18.4567 5.39716 19.1427 3.47639 19.1427C3.14826 19.1427 2.82013 19.126 2.5 19.0842C4.30872 20.2889 6.46959 21 8.78251 21Z"
                    fill="#3F3F46"
                  ></path>
                </g>
              </svg>
              Twitter
            </TwitterShareButton>
          </li>
          <li>
            <EmailShareButton
              url={currentURL}
              subject={listingTitle}
              body="Please checkout this listing"
            >
              <svg
                width="18"
                height="19"
                fill="#000000"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M22,5V9L12,13,2,9V5A1,1,0,0,1,3,4H21A1,1,0,0,1,22,5ZM2,11.154V19a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V11.154l-10,4Z"></path>
                </g>
              </svg>
              Email
            </EmailShareButton>
          </li>
          <li
            onClick={() => {
              copyToClipboard(currentURL);
              toast.success(`Copied to clipboard`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            }}
          >
            <svg
              width="18"
              height="19"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g clipPath="url(#a)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 5h7.795c1.115 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926V16a1 1 0 1 0 2 0V8.128c0-1.783-.186-2.43-.534-3.082a3.635 3.635 0 0 0-1.512-1.512C18.302 3.186 17.655 3 15.872 3H8a1 1 0 0 0 0 2zm7.721 2.334C15.314 7.116 14.91 7 13.795 7h-7.59c-1.115 0-1.519.116-1.926.334a2.272 2.272 0 0 0-.945.945C3.116 8.686 3 9.09 3 10.205v7.59c0 1.114.116 1.519.334 1.926.218.407.538.727.945.945.407.218.811.334 1.926.334h7.59c1.114 0 1.519-.116 1.926-.334.407-.218.727-.538.945-.945.218-.407.334-.811.334-1.926v-7.59c0-1.115-.116-1.519-.334-1.926a2.272 2.272 0 0 0-.945-.945z"
                    fill="#000000"
                  ></path>
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#000000" d="M0 0h24v24H0z"></path>
                  </clipPath>
                </defs>
              </g>
            </svg>
            Copy link
          </li>
        </ul>
        <Toastify />
      </div>
    </Modal>
  );

  return <div>{sharingModal}</div>;
}

export default SocialSharing;
