import React from 'react';

function IconShield() {
  return (
    <svg width="24px" height="24px" id="Capa_1" x="0px" y="0px" viewBox="0 0 297.891 297.891">
      <g>
        <path d="M264.148,40.644L153.978,0.89C152.334,0.297,150.64,0,148.917,0c-1.731,0-3.449,0.3-5.101,0.899L34.041,40.653   c-5.933,2.152-10.096,7.789-10.096,14.101v94.191c0,21.029,6.778,42.061,19.713,62.51c9.915,15.673,23.715,31.043,40.802,45.686   c28.57,24.482,56.918,38.604,58.109,39.193c2.096,1.037,4.402,1.557,6.681,1.557c2.245,0,4.505-0.504,6.577-1.512   c1.191-0.58,29.526-14.482,58.123-38.867c17.104-14.584,30.697-29.954,40.619-45.682c12.93-20.493,19.375-41.65,19.375-62.885   V54.754C273.945,48.432,270.095,42.788,264.148,40.644z M47.945,148.875V65.969c0-3.156,1.934-5.975,4.901-7.051l93.312-33.947   c0.832-0.301,1.787-0.523,2.787-0.523v124.498h101.711c0.006,0,0.013,0.135,0.013,0.221c-0.02,27.129-13.684,54.016-40.607,80.195   c-21.025,20.445-44.912,35.036-57.199,41.919c-1.139,0.639-2.917,0.94-3.917,0.94c0-2.333,0-121.982,0-123.314L47.945,148.875z" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export default IconShield;
