import classNames from 'classnames';
import React, { useEffect } from 'react';
import IconShield from '../../components/IconShield/IconShield';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import css from './ListingPage.module.css';
import { isArrayLength } from '../../util/dataExtractors';

function SectionProtection(props) {
  const { photos, selectedSize, onSetSelectedSize, updatedSizesData } = props;

  useEffect(() => {
    if (selectedSize === '' && isArrayLength(updatedSizesData)) {
      const firstSize = updatedSizesData[0];
      onSetSelectedSize(firstSize?.size?.values);
    }
  }, [updatedSizesData?.length]);

  return (
    <div
      className={classNames(
        css.trustedBuyerProtection,
        photos && photos.length > 0 ? null : css.fixWidth
      )}
    >
      <div className={css.securityIcon}>
        <IconShield />
      </div>
      <div className={css.securityInfo}>
        <h2>Tutulist Buyer Protection:</h2>
        <p>
          Get your order as described or receive your money back.
          <ExternalLink
            href="https://support.tutulist.com/hc/en-us/articles/10436226286221-Tutulist-Buyer-Protection"
            className={css.supportLink}
          >
            Learn more.
          </ExternalLink>
        </p>
      </div>
    </div>
  );
}

export default SectionProtection;
