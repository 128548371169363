import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  OrderPanel,
  ListSlider,
  Button,
  CardSpinner,
  IconNextArrow,
  IconPrevArrow,
  AspectRatioWrapper,
  ResponsiveImageWithoutZoomer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import { sendEnquiry, fetchTransactionLineItems, setInitialValues } from './ListingPage.duck';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionGallery from './SectionGallery';
import ImageGallery from 'react-image-gallery';
import {
  capitalizeFirstLetter,
  createValidVariantsParams,
  getBookmarks,
  getGTMParams,
  getMailChimpParams,
  getStoreName,
  getUsername,
  getWishlist,
  hasStripeConnected,
  isArrayLength,
  isFreeBookingFlow,
  isRentalListing,
  isSellerPaypalConnected,
  isStripeConnected,
  isValidListings,
} from '../../util/dataExtractors';
import { updateSave } from '../../ducks/user.duck';
import { withViewport } from '../../util/contextHelpers';
import SocialSharing from './SocialSharing/SocialSharing';
import SectionTags from './SectionTags';
import SectionProtection from './SectionProtection';

import Slider from 'react-slick';
import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';

import css from './ListingPage.module.css';
import { pushEventToDataLayer } from '../../util/gtm';
import { GOOGLE_TAGS } from '../../config/enums';
import moment from 'moment';
import IconArrowRight from '../../components/IconArrowRight/IconArrowRight';
import IconFreeShipping from '../../components/IconFreeShipping/IconFreeShipping';
import IconReturn from '../../components/IconReturn/IconReturn';
import IconShipping from '../../components/IconShipping/IconShipping';
import IconShippingTruck from '../../components/IconShippingTruck/IconShippingTruck';
import IconPayment from '../../components/IconPayment/IconPayment';
import SectionBrand from './SectionBrand';
import { formatDatesValue, isMobileLayout } from '../../util/genericHelpers';
import classNames from 'classnames';
import { NO_IMAGE } from '../../util/enums';
import { addStoreProductMailChimp, removeStoreProductMailChimp } from '../../ducks/Global.duck.js';
import InnerImageZoom from 'react-inner-image-zoom';
import { isWindowDefined } from '../../util/cartHelpers.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconNextArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      {' '}
      <IconPrevArrow className={css.icon} />
    </div>
  );
}

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      isSocialSharingModalOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      enquiryType: 'enquiry',
      selectedSize: '',
      profileUpdateInProgress: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onOpenShareModal = this.onOpenShareModal.bind(this);
    this.onSetSelectedSize = this.onSetSelectedSize.bind(this);
    this.onProfileUpdate = this.onProfileUpdate.bind(this);
  }

  onOpenShareModal(boolen) {
    this.setState({ isSocialSharingModalOpen: boolen });
  }
  onSetSelectedSize(size) {
    this.setState({ selectedSize: size });
  }

  onProfileUpdate(bool) {
    this.setState({ profileUpdateInProgress: bool });
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;

    let listingId = new UUID(params.id);
    let listing = getListing(listingId);
    const publicData = listing && listing.attributes && listing.attributes.publicData;

    const variantListing =
      isArrayLength(publicData?.sizes) &&
      publicData?.sizes?.find(s => s?.size?.values === this.state.selectedSize);

    if (variantListing?.id) {
      listingId = new UUID(variantListing.id);
      listing = getListing(listingId);
    }

    const { bookingDates, quantity: quantityRaw, deliveryMethod, ...otherOrderData } = values;
    const bookingDatesMaybe = bookingDates?.startDate
      ? {
          startDate: bookingDates?.startDate,
          endDate: bookingDates?.endDate,
        }
      : {};

    const currentStock = listing && listing.currentStock;
    const price = listing && listing.attributes && listing.attributes.price;
    const quantity = currentStock && currentStock.attributes && currentStock.attributes.quantity;
    const awsPhotos = publicData && publicData.photos && publicData.photos.length;
    const firstImage =
      (listing &&
        listing.images &&
        listing.images[0] &&
        listing.images[0].attributes &&
        listing.images[0].attributes.variants &&
        listing.images[0].attributes.variants['listing-card'] &&
        listing.images[0].attributes.variants['listing-card'].url) ||
      (awsPhotos && awsPhotos[0]) ||
      NO_IMAGE;

    const shippingPriceInSubunitsOneItem = publicData && publicData.shippingPriceInSubunitsOneItem;
    const shippingPriceInSubunitsAdditionalItems =
      publicData && publicData.shippingPriceInSubunitsAdditionalItems;
    const currency = price && price.currency;
    const sku = publicData && publicData.sku;
    const cartItems = [
      {
        customCommission: listing?.author?.attributes?.profile?.publicData?.customCommission,
        listingId: params.id,
        stockCount: 1,
        price: listing?.attributes?.price,
        title: listing?.attributes?.title,
        authorId: listing?.author?.id?.uuid,
        shippingPriceInSubunitsOneItem,
        shippingPriceInSubunitsAdditionalItems,
        currency,
        oldStock: quantity,
        firstImage,
        sku,
        productInfo: {
          item_brand: publicData?.brand,
          item_category: publicData?.category,
          item_category2: publicData?.condition,
          item_category3: publicData?.color,
          item_category4: publicData?.size,
          item_variant: publicData?.sku || '',
        },
      },
    ];

    const isPaypal = isSellerPaypalConnected(listing?.author);
    const isCustomFlow = isFreeBookingFlow(listing?.author);

    const initialValues = {
      listing,
      orderData: {
        bookingDates: bookingDatesMaybe,
        quantity: Number.parseInt(1, 10),
        deliveryMethod: 'shipping',
        isPaypal,
        isCustomFlow,
        otherOrderData: {
          cartItems,
        },
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    const productParams = Array.isArray(cartItems)
      ? cartItems?.map((l, i) => {
          const item_id = l?.listingId;
          const item_name = l?.title;
          const quantity = l?.stockCount;
          const price = l?.price?.amount / 100;
          const index = i;
          const productInfo = l.productInfo;
          return { item_id, item_name, quantity, price, index, ...productInfo };
        })
      : [];

    const totalOrderValue =
      isArrayLength(productParams) &&
      productParams.map(e => e.price * e.quantity).reduce((partialSum, a) => partialSum + a, 0);

    pushEventToDataLayer(productParams, GOOGLE_TAGS.BEGIN_CHECKOUT, totalOrderValue);
    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
        },
        {}
      )
    );
  }

  onContactUser(type = null) {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      if (type) {
        Object.assign(state, { type });
      }
      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true, enquiryType: type });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, currentUser, getListing } = this.props;
    const routes = routeConfiguration();
    let listingId = new UUID(params.id);
    const isOffer = this.state.enquiryType === 'offer';
    const { message = '' } = values;
    const { offerPrice = '' } = values;
    const offerParams = isOffer
      ? {
          isOffer,
          offerPrice,
          authorId: currentUser?.id?.uuid,
          createdAt: moment().unix(),
          type: 'initial',
        }
      : null;
    const messageParams = isOffer
      ? `I would like to make an offer for $${offerPrice}`
      : message?.trim();

    let listing = getListing(listingId);
    const publicData = listing && listing.attributes && listing.attributes.publicData;

    const variantListing =
      isArrayLength(publicData?.sizes) &&
      publicData?.sizes?.find(s => s?.size?.values === this.state.selectedSize);

    if (variantListing?.id) {
      listingId = new UUID(variantListing.id);
    }

    onSendEnquiry({ listingId }, messageParams, offerParams)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        const params = !isOffer ? { tab: 'enquiry' } : {};
        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString(
            isOffer ? 'OffersPage' : 'MessagesOrderPage',
            routes,
            params,
            {}
          )
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      viewport,
      history,
      onUpdateProfile,
      authorListings,
      similarListings,
      sizesListings,
      saveCountBySeller,
      onAddStoreProductMailChimp,
      onRemoveStoreProductMailChimp,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'info';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const isRental = isRentalListing(currentListing);

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain className={css.listingPageWrapper}>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain className={css.listingPageWrapper}>
              <div className={css.contentWrapperForProductLayout}>
                <div className={css.spinner}>
                  <CardSpinner />
                </div>
              </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    // const { formattedPrice = '', priceTitle } = price && price.amount && priceData(price, intl);

    const localBookmarksParsing =
      isWindowDefined() && window.localStorage.getItem('localBookmarks');

    let localBookmarks = isArrayLength(localBookmarksParsing)
      ? window.localStorage.getItem('localBookmarks')
      : [];

    if (typeof localBookmarks === 'string') {
      localBookmarks = isWindowDefined() && JSON.parse(localBookmarksParsing);
    }

    const handleOrderSubmit = values => {
      const id = listingId?.uuid;
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (!isAuthenticated && !isRental) {
        const localIndex = localBookmarks && localBookmarks.findIndex(b => b == id);
        if (localIndex > -1) {
          localBookmarks && localBookmarks.splice(localIndex, 1);
          const removedBookmarks = Array.from(new Set(localBookmarks));
          isWindowDefined() &&
            window.localStorage.setItem('localBookmarks', JSON.stringify(removedBookmarks));
        } else {
          localBookmarks.push(id);
          const addedBookmarks = Array.from(new Set(localBookmarks));
          isWindowDefined() &&
            window.localStorage.setItem('localBookmarks', JSON.stringify(addedBookmarks));
        }

        history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), {
          from: '/cart',
        });
      } else if (isRental) {
        if (isAuthenticated) {
          this.handleSubmit(values);
        } else {
          const datesQuery = formatDatesValue(values?.bookingDates);
          history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), {
            from: `${location.pathname}?dates=${datesQuery}`,
          });
        }
      } else {
        if (isOwnListing || isCurrentlyClosed) {
          window.scrollTo(0, 0);
        } else {
          const selectedSizeVariant =
            isArrayLength(sizesListings) &&
            sizesListings.find(s => s?.attributes?.publicData?.size === this.state.selectedSize);

          const id = selectedSizeVariant?.id?.uuid || rawParams?.id;

          if (id) {
            this.onProfileUpdate(true);
            bookmarks.push(id);
            pushEventToDataLayer(
              getGTMParams(currentListing, currentUser),
              GOOGLE_TAGS.ADD_TO_CART
            );
            onUpdateProfile({
              publicData: { bookmarks: Array.from(new Set(bookmarks)) },
            }).then(() => {
              onAddStoreProductMailChimp({
                productParams: getMailChimpParams(currentListing),
                currentUser,
                isSyncedWithMC: publicData?.isSyncedWithMC,
              });
              history.push(createResourceLocatorString('CartPage', routeConfiguration(), {}, {}));
            });
          }
        }
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ListingPage.schemaTitle' }, { title, siteTitle });
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product

    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};

    // const schemaPriceNumber =
    //   price &&
    //   intl.formatNumber(convertMoneyToNumber(price), {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   });

    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const storeName = getStoreName(ensuredAuthor);
    const username = getUsername(ensuredAuthor);

    const authorLink = ensuredAuthor && ensuredAuthor.id && (
      <NamedLink
        className={css.authorNameLink}
        name={username ? 'StorePage' : 'ProfilePage'}
        params={
          username
            ? { username: username || ensuredAuthor?.id?.uuid }
            : { id: ensuredAuthor?.id?.uuid }
        }
        to={{ state: ensuredAuthor?.id?.uuid }}
      >
        {storeName ? storeName : authorDisplayName}
      </NamedLink>
    );

    const bookmarks = getBookmarks(currentUser);

    const photos =
      isArrayLength(publicData?.photos) &&
      publicData.photos.map(item => {
        return {
          original: item,
          thumbnail: item,
        };
      });

    const isShipping =
      publicData && publicData.shippingEnabled
        ? publicData.shippingPriceInSubunitsOneItem / 100
        : null;

    const wishlist = getWishlist(currentUser);

    const breadcrumbs = (
      <div className={css.breadcrumbs}>
        <NamedLink
          className={css.link}
          name="SearchPage"
          to={{ search: `?pub_category=${publicData?.category}` }}
        >
          {publicData?.category ? capitalizeFirstLetter(publicData?.category) : ''}
        </NamedLink>
        <IconNextArrow />
        <NamedLink
          className={css.link}
          name="SearchPage"
          to={{ search: `?pub_type=${publicData?.type}` }}
        >
          {publicData?.type}
        </NamedLink>
        <IconNextArrow />
        <NamedLink className={css.link} name="SearchPage" to={{ search: `?pub_brand=${brand}` }}>
          {publicData?.brand}
        </NamedLink>
        <IconNextArrow />
        <span className={css.current}>{title}</span>
      </div>
    );

    const isVacationMode = publicData?.vacationMode;
    const shouldHideBookingPanel =
      currentListing?.attributes?.state === LISTING_STATE_CLOSED ||
      currentListing.currentStock?.attributes?.quantity == 0;

    const updatedSizesData = createValidVariantsParams(sizesListings);

    const variantListing =
      isArrayLength(publicData?.sizes) &&
      publicData?.sizes?.find(s => s?.size?.values === this.state.selectedSize);

    const renderThumbnail = (
      <div>
        <img
          alt="img"
          src="https://tutulist-product-photos.s3.us-east-2.amazonaws.com/081822/081822-18.2.jpeg"
        />
      </div>
    );

    const settings = {
      dots: false,
      dotsClass: 'slick-dots slick-thumb',
      fade: true,
      infinite: true,
      lazyLoad: true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      pauseOnHover: true,
      nextArrow: <RenderNextArrow />,
      prevArrow: <RenderPrevArrow />,
      // customPaging: index => renderThumbnail,
    };

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            availability: schemaAvailability,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.listingPageWrapper}>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainContentWrapper}>
                <div className={css.mainColumnForProductLayout}>
                  {isMobileLayout() ? (
                    <div
                      className={classNames(css.orderColumnForProductLayout, css.mobileOrderColumn)}
                    >
                      {breadcrumbs}
                    </div>
                  ) : null}
                  <div
                    className={classNames(css.gallarySection, {
                      [css.gallarySlider]: true,
                    })}
                  >
                    <div className={css.soldBy}>
                      <FormattedMessage id="ListingPage.soldBy" values={{ name: authorLink }} />
                    </div>
                    {isArrayLength(photos) ? (
                      <Slider {...settings}>
                        {publicData?.photos?.map(p => (
                          <InnerImageZoom
                            zoomType="hover"
                            zoomScale={2.2}
                            // className={classes}
                            hideHint
                            src={p}
                            zoomSrc={p}
                            className={css.sliderItem}
                          />
                        ))}
                      </Slider>
                    ) : (
                      <SectionGallery
                        listing={currentListing}
                        onManageDisableScrolling={onManageDisableScrolling}
                      />
                    )}
                  </div>
                  <SectionProtection
                    selectedSize={this.state.selectedSize}
                    onSetSelectedSize={this.onSetSelectedSize}
                    updatedSizesData={updatedSizesData}
                    photos={photos}
                  />
                </div>
                <div className={css.orderColumnForProductLayout}>
                  {isMobileLayout() ? null : breadcrumbs}
                  <div className={css.productDetails}>
                    <SectionDescriptionMaybe
                      currentListing={currentListing}
                      currentUser={currentUser}
                      isAuthenticated={isAuthenticated}
                      onUpdateProfile={onUpdateProfile}
                      shouldHideBookingPanel={shouldHideBookingPanel || isVacationMode}
                      listingTitle={richTitle}
                      ensuredAuthor={ensuredAuthor}
                      images={currentListing?.images}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      id={listingId && listingId.uuid}
                      isOwnListing={isOwnListing}
                      publicData={publicData}
                      saveCountBySeller={saveCountBySeller}
                      onOpenShareModal={this.onOpenShareModal}
                      selectedSize={this.state.selectedSize}
                    />
                    <div className={css.description}>{description}</div>
                    {isRental ? null : (
                      <div className={css.priceBox}>
                        <p className={css.price}>
                          ${price && price.amount ? (price.amount / 100 || 0).toFixed(2) : null}
                        </p>
                        <sup className={css.shippingPrice}>
                          {' '}
                          {publicData?.weight
                            ? null
                            : isShipping
                            ? `( + $${isShipping ? isShipping.toFixed(2) : ''} Shipping )`
                            : 'Free Shipping'}
                        </sup>
                      </div>
                    )}
                  </div>
                  {isArrayLength(publicData?.sizes) ? (
                    <p className={css.singleVariant}>
                      Size :{' '}
                      <span>
                        {variantListing
                          ? variantListing?.size?.label
                          : publicData?.sizeOption?.label}
                      </span>
                    </p>
                  ) : null}
                  {isArrayLength(updatedSizesData) ? (
                    <div className={css.selectSize}>
                      {updatedSizesData?.map((s, i) => (
                        <span
                          key={i}
                          className={classNames(css.sizePill, {
                            [css.selectedSize]:
                              i == 0 && !this.state.selectedSize
                                ? true
                                : this.state.selectedSize === s?.size?.values,
                          })}
                          onClick={e => {
                            this.onSetSelectedSize(s?.size?.values);
                          }}
                        >
                          {s?.size?.label}
                        </span>
                      ))}
                    </div>
                  ) : null}
                  {shouldHideBookingPanel || isVacationMode ? (
                    <div className={css.outOfStock}>
                      {isVacationMode ? null : <Button disabled>Out of stock</Button>}
                    </div>
                  ) : (
                    <OrderPanel
                      className={css.productOrderPanel}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      profileUpdateInProgress={this.state.profileUpdateInProgress}
                      onSubmit={handleOrderSubmit}
                      title={bookingTitle}
                      author={ensuredAuthor}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onContactUser={this.onContactUser}
                      timeSlots={timeSlots}
                      fetchTimeSlotsError={fetchTimeSlotsError}
                      onFetchTransactionLineItems={onFetchTransactionLineItems}
                      lineItems={lineItems}
                      fetchLineItemsInProgress={fetchLineItemsInProgress}
                      fetchLineItemsError={fetchLineItemsError}
                    />
                  )}
                  <SectionDetailsMaybe
                    publicData={publicData}
                    customConfig={customConfig}
                    hasVariants={isArrayLength(publicData?.sizes)}
                  />
                  {listingId?.uuid && isOwnListing ? (
                    <div className={css.editAction}>
                      <NamedLink
                        name="EditListingPage"
                        params={{
                          id: listingId.uuid,
                          slug: listingSlug,
                          type: listingType,
                          tab: isRental ? 'rental' : listingTab,
                        }}
                      >
                        <Button type="button">
                          <FormattedMessage id="ListingPage.editTitle" values={{ title }} />{' '}
                        </Button>
                      </NamedLink>
                    </div>
                  ) : null}
                  <SectionTags publicData={publicData} history={history} />
                  <SectionAuthorMaybe
                    title={title}
                    currentAuthor={currentAuthor}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    enquiryType={this.state.enquiryType}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  <SectionBrand listing={currentListing} />
                </div>
              </div>
              <div className={css.moreItemSeller}>
                {isArrayLength(authorListings) ? (
                  <>
                    <ListSlider
                      title="ListingPage.moreItems"
                      viewport={viewport}
                      listings={authorListings || []}
                      intl={intl}
                      sliderHeading={css.sliderHeading}
                      homeMobileSlider={css.homeMobileSlider}
                      isAuthenticated={isAuthenticated}
                      isListingPageSlider={true}
                      isOwnListing={isOwnListing}
                      currentUser={currentUser}
                      onUpdateProfile={onUpdateProfile}
                    />
                  </>
                ) : null}
              </div>
              <div className={css.moreItemSeller}>
                {isArrayLength(similarListings) ? (
                  <>
                    <ListSlider
                      title="ListingPage.similarItems"
                      viewport={viewport}
                      listings={similarListings || []}
                      intl={intl}
                      sliderHeading={css.sliderHeading}
                      homeMobileSlider={css.homeMobileSlider}
                      bookmarks={bookmarks}
                      isAuthenticated={isAuthenticated}
                      wishlist={wishlist}
                      queryInProgress={false}
                      isListingPageSlider={true}
                      isOwnListing={isOwnListing}
                      currentUser={currentUser}
                      onUpdateProfile={onUpdateProfile}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <SocialSharing
              onManageDisableScrolling={onManageDisableScrolling}
              isSocialSharingModalOpen={this.state.isSocialSharingModalOpen}
              onOpenShareModal={this.onOpenShareModal}
              location={location}
              listing={currentListing}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    authorListingsIds,
    similarListingsIds,
    saveCountBySeller,
    sizesListingsIds,
  } = state.ListingPage;

  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const authorListings =
    isArrayLength(authorListingsIds) && getListingsById(state, authorListingsIds);

  const similarListings =
    isArrayLength(similarListingsIds) && getListingsById(state, similarListingsIds);

  const sizesListings = isArrayLength(sizesListingsIds) && getListingsById(state, sizesListingsIds);

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sizesListings,
    authorListings: isValidListings(authorListings),
    similarListings: isValidListings(similarListings),
    saveCountBySeller,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (params, message, isOffer) => dispatch(sendEnquiry(params, message, isOffer)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onUpdateSave: data => dispatch(updateSave(data)),
  onAddStoreProductMailChimp: data => dispatch(addStoreProductMailChimp(data)),
  onRemoveStoreProductMailChimp: data => dispatch(removeStoreProductMailChimp(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(ListingPageComponent);

export default ListingPage;
